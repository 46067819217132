
































import {Component, Emit, Prop, Vue} from "vue-property-decorator";

@Component
export default class PermanentDialog extends Vue {

  @Prop({default: false}) show!: boolean;
  @Prop() question!: string;
  @Prop() message!: string;


  get dialog(): boolean {
    return this.show
  }

  set dialog(value: boolean) {
    this.show = value;
  }

  @Emit('agree')
  agree(): boolean {
    this.dialog = false;
    return true;
  }

  @Emit('cancel')
  cancel(): boolean {
    this.dialog = false;
    return true;
  }

  mounted() {
    //
  }
}
