import {workOutPlanApi} from "@/api/api.instance";
import {AxiosPromise} from "axios";
import {WorkOutPlan, WorkOutStatus} from "@/api";

export class WorkOutPlanService {
    getWorkOutPlan(id: number): AxiosPromise<WorkOutPlan> {
        return workOutPlanApi.getWorkOutPlan(id);
    }

    deleteWorkOutPlan(id: number): AxiosPromise<void> {
        return workOutPlanApi.deleteWorkOutPlan(id);
    }

    updateWorkOutPlan(id: number, payload: WorkOutPlan): AxiosPromise<WorkOutPlan> {
        return workOutPlanApi.updateWorkOutPlan(id, payload);
    }

    getAllWorkOutPlans(): AxiosPromise<Array<WorkOutPlan>> {
        return workOutPlanApi.getAllWorkOutPlans();
    }

    getAllUserEditableWorkOutPlans(): AxiosPromise<Array<WorkOutPlan>> {
        return workOutPlanApi.getAllUserEditableWorkOutPlans();
    }

    getAllUserWorkOutPlans(): AxiosPromise<Array<WorkOutPlan>> {
        return workOutPlanApi.getAllUserWorkOutPlans();
    }

    createWorkoutPlan(payload: WorkOutPlan): AxiosPromise<WorkOutPlan> {
        return workOutPlanApi.createWorkoutPlan(payload);
    }

    confirmWorkOutPlan(id: number): AxiosPromise<WorkOutPlan> {
        return workOutPlanApi.confirmWorkOutPlan(id);
    }

    updateWorkOutStatus(id: number, workoutId: number, status: WorkOutStatus): AxiosPromise<WorkOutPlan> {
        return workOutPlanApi.updateWorkOutStatus(id, workoutId, status);
    }
}

export default new WorkOutPlanService();
