


















































































































































































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {Skill, User, WorkOut, WorkOutPlan, WorkOutPlanStatus, WorkOutStatus} from "@/api";
import workOutPlanService from "@/service/workout-plan.service";
import moment from "moment";
import {STANDARD_DATE} from "@/constants/date.constants";
import PermanentDialog from "@/components/dialogs/PermanentDialog.vue";
import notificationService, {NotificationType} from "@/service/notification.service";

const Auth = namespace("Auth");
@Component({
  components: {PermanentDialog}
})
export default class WorkoutPlanEditForm extends Vue {

  @Auth.State("user")
  private currentUser!: User;

  workOutPlan: WorkOutPlan = {};
  confirmDialog = false;
  deleteDialog = false;

  get items(): WorkOut[] {
    return this.workOutPlan.items?.sort((a, b) => (a.contract?.client?.lastname! > b.contract?.client?.lastname! ? 1 : -1)) || [];
  }

  code(item: WorkOutPlan): string {
    return item?.group?.code!;
  }

  submit(): void {
    this.confirmDialog = false;
    workOutPlanService.confirmWorkOutPlan(this.workOutPlan.id!).then(resp => {
      this.workOutPlan = resp.data;
      this.$router.push('/trainer');
    }).catch(error => {
      notificationService.showNotification(NotificationType.error, error.response.data.detail)
    })
  }

  deleteWorkOutPlan(): void {
    this.deleteDialog = false;
    workOutPlanService.deleteWorkOutPlan(this.workOutPlan.id!).then(() => {
      this.$router.push('/trainer');
    }).catch(error => {
      notificationService.showNotification(NotificationType.error, error.response.data.detail)
    })
  }

  cancelSubmit() {
    this.confirmDialog = false;
  }

  cancelDelete() {
    this.deleteDialog = false;
  }

  showConfirmDialog(): void {
    this.confirmDialog = !this.confirmDialog;
  }

  showDeleteDialog(): void {
    this.deleteDialog = !this.deleteDialog;
  }

  isPresent(item: WorkOut): boolean {
    return item.status === WorkOutStatus.PRESENT;
  }

  changeWorkoutStatus(item: WorkOut) {
    const status = item.status === WorkOutStatus.PRESENT ? WorkOutStatus.NOTPRESENT : WorkOutStatus.PRESENT;
    workOutPlanService.updateWorkOutStatus(+this.workOutPlan.id!, item.id! as number, status).then(resp => {
      this.workOutPlan = resp.data;
    }).catch(error => {
      notificationService.showNotification(NotificationType.error, error.response.data.detail)
    })

  }


  clientName(item: WorkOut): string {
    return item?.contract?.client?.firstname + ' ' + item?.contract?.client?.lastname;
  }

  cardColor(status: WorkOutStatus): string {
    return status === WorkOutStatus.PRESENT ? 'light-green' : 'amber';
  }

  cardIcon(status: WorkOutStatus): string {
    return status === WorkOutStatus.PRESENT ? 'mdi-account-check-outline' : 'mdi-account-cancel-outline';
  }

  formatDate(date: string): string {
    return moment(date).format(STANDARD_DATE)
  }

  loadWorkoutPlan(id: number): void {
    workOutPlanService.getWorkOutPlan(id).then(resp => {
      this.workOutPlan = resp.data;
    }).catch(error => {
      notificationService.showNotification(NotificationType.error, error.response.data.detail)
    });
  }

  age(item: WorkOut): number {
    return item.contract?.client?.age!;
  }

  phone(item: WorkOut): string {
    return item.contract?.client?.phone!;
  }

  email(item: WorkOut): string {
    return item.contract?.client?.email!;
  }

  canSwim(item: WorkOut): boolean {
    return !item.contract?.client?.skills?.includes(Skill.NOSKILL)!;
  }

  statusColor(plan: WorkOutPlan): string {
    return plan.status === WorkOutPlanStatus.CONFIRMED ? 'success' : 'grey'
  }

  confirmed(plan: WorkOutPlan): boolean {
    return plan.status === WorkOutPlanStatus.CONFIRMED
  }

  panelColor(plan: WorkOutPlan): string {
    return plan.status === WorkOutPlanStatus.CONFIRMED ? 'success' : 'lime'
  }

  statusIcon(plan: WorkOutPlan): string {
    return plan.status === WorkOutPlanStatus.CONFIRMED ? 'mdi-check-circle-outline' : 'mdi-pause-circle-outline'
  }

  remove(): void {
    this.workOutPlan.group = undefined;
  }

  mounted() {
    const id = this.$route.params?.id!;
    if (id) {
      this.loadWorkoutPlan(+id!);
    }
  }

}
